import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

//import "../styles/mystyles.scss"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="section">
      <div className="container">
        <h1 className="title">NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
